<template>
  <div class="pa-4 text-center">
    <v-dialog v-model="dialog" max-width="700" @click:outside="$emit('close')">
      <v-card title="Video">
        <div v-if="isYoutube()" ref="videoContainer" class="center" v-html="currentVideoContent"></div>
        <div v-else  class="center"><video :src="'https://admin.tumar.com' + currentVideoContent" controls width="100%"></video>></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" variant="outlined" @click="closeModal"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    video: String,
    dialog: Boolean,
  },
  data() {
    return {
      currentVideoContent: this.video
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.currentVideoContent = '';
      } else {
        this.currentVideoContent = this.video;
      }
    }
  },
  methods: {
    isYoutube() {
      return this.video.includes('<iframe');
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
</style>
