import { render, staticRenderFns } from "./VideoModal.vue?vue&type=template&id=29b7ef02&scoped=true&"
import script from "./VideoModal.vue?vue&type=script&lang=js&"
export * from "./VideoModal.vue?vue&type=script&lang=js&"
import style0 from "./VideoModal.vue?vue&type=style&index=0&id=29b7ef02&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b7ef02",
  null
  
)

export default component.exports